import React from "react";
import InputForm from "./form/InputForm";
import { Toast } from "./Toast";
import Input from "./input/input";
import useGrant from "../hooks/loan/useGrant";
import arrow from "../assets/arrow-left.svg";
import { motion } from "framer-motion";
import image from "../assets/success.svg";

export default function GrantForm() {
  const {
    hiddenFileInput,
    file,
    sectionRef,
    view,
    inputFile,
    genderMenu,
    setGenderMenu,
    handleChange,
    handleViewChange,
    handleSubmit,
    handleFileChange,
    genderListMenu,
    file2,
    hiddenFileInput2,
    handleFileChange2,
    genderStatus,
    setGenderStatus,
    relationStatus,
    relationMenu,
    relationListMenu,
    setRelationMenu,
    setRelationStatus,
    loading,
    levelListMenu,
    setLevelListMenu,
    levelMenu,
    setLevelMenu,
    levelStatus,
    setLevelStatus,
    handleClick,
    handleFileChange3,
    handleFileChange4,
    handleFileChange6,
    handleFileChange5,
    handleFileChange7,
    file3,
    file4,
    file5,
    file6,
    file7,
    handleClick2,
    handleClick3,
    handleClick4,
    handleClick5,
    handleClick6,
    handleClick7,
    hiddenFileInput3,
    hiddenFileInput4,
    hiddenFileInput5,
    hiddenFileInput6,
    hiddenFileInput7,
    clipText,
  } = useGrant();
  return (
    <form
      style={{
        background: "#F8F7FC",
      }}
      ref={sectionRef}
      className="py-40"
    >
      <h2 className="my-4 text-center text-xl font-medium capitalize">
        Kindly fill the form below!
      </h2>

      <div className="mx-auto flex w-full flex-col space-y-4 rounded bg-white p-5 shadow sm:w-3/4 lg:w-1/2">
        {view === 5 && <SuccessGrant handleViewChange={handleViewChange} />}
        {view === 1 && (
          <>
            <p className="text-center text-xs text-gray-500">step 1/4</p>
            <h1 className="my-5 text-center font-semibold ">Personal data</h1>
            <InputSegment
              handleViewChange={handleViewChange}
              inputFile={inputFile}
              handleChange={handleChange}
              genderListMenu={genderListMenu}
              genderStatus={genderStatus}
              setGenderStatus={setGenderStatus}
              setGenderMenu={setGenderMenu}
              genderMenu={genderMenu}
            />
          </>
        )}
        {view === 2 && (
          <>
            <img
              src={arrow}
              onClick={() => handleViewChange(1)}
              className="absolute w-7 cursor-pointer"
              alt="back"
            />
            <p className="text-center text-xs text-gray-500">step 2/4</p>
            <h1 className="my-5 text-center font-semibold ">Education data</h1>
            <InputSegmentOne
              handleViewChange={handleViewChange}
              inputFile={inputFile}
              handleChange={handleChange}
              levelListMenu={levelListMenu}
              setLevelListMenu={setLevelListMenu}
              levelMenu={levelMenu}
              setLevelMenu={setLevelMenu}
              levelStatus={levelStatus}
              setLevelStatus={setLevelStatus}
            />
          </>
        )}
        {view === 3 && (
          <>
            <img
              src={arrow}
              onClick={() => handleViewChange(2)}
              className="absolute w-7 cursor-pointer"
              alt="back"
            />
            <p className="text-center text-xs text-gray-500">step 3/4</p>
            <h1 className="my-5 text-center font-semibold ">Parent data</h1>
            <InputSegmentTwo
              handleViewChange={handleViewChange}
              inputFile={inputFile}
              handleChange={handleChange}
              setRelationStatus={setRelationStatus}
              relationMenu={relationMenu}
              relationListMenu={relationListMenu}
              setRelationMenu={setRelationMenu}
              relationStatus={relationStatus}
            />
          </>
        )}
        {view === 4 && (
          <>
            <img
              src={arrow}
              onClick={() => handleViewChange(3)}
              className="absolute w-7 cursor-pointer"
              alt="back"
            />
            <p className="textp-xs text-center text-gray-500">step 4/4</p>
            <h1 className="my-5 text-center font-semibold ">Document Upload</h1>
            <InputSegmentThree
              file={file}
              hiddenFileInput={hiddenFileInput}
              hiddenFileInput2={hiddenFileInput2}
              file2={file2}
              handleFileChange2={handleFileChange2}
              handleFileChange={handleFileChange}
              handleSubmit={handleSubmit}
              loading={loading}
              handleClick={handleClick}
              handleFileChange3={handleFileChange3}
              handleFileChange4={handleFileChange4}
              handleFileChange6={handleFileChange6}
              handleFileChange5={handleFileChange5}
              handleFileChange7={handleFileChange7}
              file3={file3}
              file4={file4}
              file5={file5}
              file6={file6}
              file7={file7}
              handleClick2={handleClick2}
              handleClick3={handleClick3}
              handleClick4={handleClick4}
              handleClick5={handleClick5}
              handleClick6={handleClick6}
              handleClick7={handleClick7}
              hiddenFileInput3={hiddenFileInput3}
              hiddenFileInput4={hiddenFileInput4}
              hiddenFileInput5={hiddenFileInput5}
              hiddenFileInput6={hiddenFileInput6}
              hiddenFileInput7={hiddenFileInput7}
              clipText={clipText}
            />
          </>
        )}
      </div>
    </form>
  );
}

export const InputSegment = ({
  handleViewChange,
  inputFile,
  handleChange,
  genderStatus,
  setGenderStatus,
  genderListMenu,
  setGenderMenu,
  genderMenu,
}) => {
  const proceed = (e) => {
    e?.preventDefault();
    const containsNonNumber = (str) => {
      const regex = /[^0-9]/; // Matches any character that is not a digit
      return regex.test(str);
    };
    if (inputFile?.email === "") {
      Toast({ title: "Input Email", error: true });
      return;
    }
    if (inputFile?.email) {
      const isValidFormat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputFile?.email);
      if (!isValidFormat) {
        Toast({ title: "Invalid Email", error: true });
        return;
      }
    }
    if (inputFile?.phone === "") {
      Toast({ title: "Input Phone Number", error: true });
      return;
    }
    if (inputFile?.phone?.length !== 11) {
      Toast({
        title: "Invalid Phone Number",
        error: true,
      });
      return;
    }
    if (containsNonNumber(inputFile?.phone)) {
      Toast({
        title: "Invalid Phone Number",
        error: true,
      });
      return;
    }
    if (inputFile?.fname === "") {
      Toast({ title: "Input Firstname", error: true });
      return;
    }
    if (inputFile?.lname === "") {
      Toast({ title: "Input Lastname", error: true });
      return;
    }
    if (inputFile?.address === "") {
      Toast({ title: "Input Address", error: true });
      return;
    }
    if (inputFile?.age === "") {
      Toast({ title: "Input Age", error: true });
      return;
    }
    if (inputFile?.fcl === "") {
      Toast({
        title: "Input Your Fast Credit Account Number",
        error: true,
      });
      return;
    }
    if (containsNonNumber(inputFile?.fcl)) {
      Toast({
        title: "Invalid Fast Credit Account Number",
        error: true,
      });
      return;
    }
    if (inputFile?.fcl?.length !== 10) {
      Toast({
        title: "Invalid Fast Credit Account Number",
        error: true,
      });
      return;
    }
    if (genderMenu === "") {
      Toast({
        title: "Select Gender",
        error: true,
      });
      return;
    } else {
      handleViewChange(2);
    }
  };
  return (
    <div className="w-full">
      <div className="flex flex-col space-x-2 sm:flex-row sm:items-center sm:justify-between">
        <div className="w-full sm:w-1/2">
          <InputForm
            label="First Name:"
            type="text"
            value={inputFile?.fname}
            handleChange={handleChange}
            name="fname"
            placeholder="john doe"
          />{" "}
        </div>
        <div className="w-full sm:w-1/2">
          <InputForm
            label="Last Name:"
            type="text"
            value={inputFile?.lname}
            handleChange={handleChange}
            name="lname"
            placeholder="john  doe"
          />
        </div>
      </div>
      <div className="flex flex-col space-x-2 sm:flex-row sm:items-center sm:justify-between">
        <div className="w-full sm:w-1/2">
          <InputForm
            label="Phone Number:"
            type="text"
            value={inputFile?.phone}
            handleChange={handleChange}
            length={11}
            name="phone"
            placeholder="0123456789"
          />
        </div>
        <div className="w-full sm:w-1/2">
          <InputForm
            label="Email:"
            type="text"
            value={inputFile?.email}
            handleChange={handleChange}
            name="email"
            placeholder="john@doe.com"
          />
        </div>
      </div>
      <div className="flex flex-col space-x-2 sm:flex-row sm:items-center sm:justify-between">
        <div className="w-full sm:w-1/2">
          <InputForm
            label="Age:"
            type="number"
            value={inputFile?.age}
            handleChange={handleChange}
            name="age"
            max={100}
            placeholder="22"
          />
        </div>
        <div className="w-full sm:w-1/2">
          <Input
            label="Gender"
            dropdown={true}
            action={(e) => {
              setGenderMenu(e.target.outerText);
              setGenderStatus(!genderStatus);
            }}
            product={genderMenu}
            dropdownAction={() => {
              setGenderStatus(!genderStatus);
            }}
            dropdownList={genderListMenu}
            productStatus={genderStatus}
          />
        </div>
      </div>
      <InputForm
        label="FCL account number:"
        type="text"
        value={inputFile?.fcl}
        handleChange={handleChange}
        length={10}
        name="fcl"
        placeholder="100010101"
      />{" "}
      <InputForm
        label="Home Address:"
        type="text"
        value={inputFile?.address}
        handleChange={handleChange}
        name="address"
        length={200}
        placeholder="lagos, nigeria"
      />
      <button
        onClick={proceed}
        className="mx-auto mt-5 flex w-3/4 items-center justify-center rounded-full bg-[#029247] px-6 py-3 font-semibold text-white sm:w-1/2"
      >
        Continue
      </button>
    </div>
  );
};
export const InputSegmentOne = ({
  handleViewChange,
  inputFile,
  handleChange,
  levelListMenu,
  setLevelListMenu,
  levelMenu,
  setLevelMenu,
  levelStatus,
  setLevelStatus,
}) => {
  const proceed = (e) => {
    e?.preventDefault();

    if (inputFile?.institution === "") {
      Toast({ title: "Input Institution", error: true });
      return;
    }
    if (inputFile?.CGPA === "") {
      Toast({ title: "Input CGPA", error: true });
      return;
    }

    if (inputFile?.level === "") {
      Toast({ title: "Input Current Level", error: true });
      return;
    }
    if (inputFile?.study === "") {
      Toast({ title: "Input Year of study", error: true });
      return;
    }
    // if (Number(inputFile?.CGPA) > 7) {
    //   Toast({ title: "Invalid CGPA", error: true });
    //   return;
    // }
    // if (Number(inputFile?.CGPA) < 3.7) {
    //   Toast({
    //     title:
    //       "Not Eligible, Only applicants with a CGPA of 3.70 and above qualify",
    //     error: true,
    //   });
    //   return;
    // }
    if (inputFile?.extracurricular === "") {
      Toast({ title: "Input Extracurricular activities", error: true });
      return;
    }
    if (inputFile?.experience === "") {
      Toast({ title: "Share Your Leadership experience", error: true });
      return;
    } else {
      handleViewChange(3);
    }
  };
  return (
    <div className="w-full">
      <div className="flex flex-col space-x-2 sm:flex-row sm:items-center sm:justify-between">
        <div className="w-full sm:w-1/2">
          <InputForm
            label="Current institution name:"
            type="text"
            value={inputFile?.institution}
            handleChange={handleChange}
            name="institution"
            length={50}
            placeholder="UNILAG"
          />
        </div>
        <div className="w-full sm:w-1/2">
          <InputForm
            label="Cumulative Grade Point Average (CGPA): "
            type="text"
            value={inputFile?.CGPA}
            handleChange={handleChange}
            name="CGPA"
            length={4}
            placeholder="3.7"
          />
        </div>
      </div>

      {/* <div className="w-full sm:w-1/2">
          <div className="input__form">
            <label htmlFor="studyYear" className="label ">
              Year of study:
            </label>
            <input
              type="date"
              style={{
                width: "auto",
                borderBottom: inputFile?.studyYear
                  ? "2px solid #029247"
                  : "none",
                background: inputFile?.studyYear ? "#F2FCF5" : "#F6F6F6",
              }}
              onChange={handleChange}
              name="studyYear"
              className="rounded input"
              value={inputFile?.studyYear}
              //   min={new Date().toISOString().split("T")[0]}
            />
          </div>
        </div> */}
      <div className="flex flex-col space-x-2 sm:flex-row sm:items-center sm:justify-between">
        <div className="w-full sm:w-1/2">
          <InputForm
            label="Course of study:"
            type="text"
            value={inputFile?.study}
            handleChange={handleChange}
            length={50}
            name="study"
            placeholder="Business Admin"
          />
        </div>
        <div className="w-full sm:w-1/2">
          <InputForm
            label="Extracurricular activities:"
            type="text"
            value={inputFile?.extracurricular}
            handleChange={handleChange}
            length={50}
            name="extracurricular"
            placeholder="reading.."
          />
        </div>
      </div>
      <Input
        label="Current Level:"
        dropdown={true}
        action={(e) => {
          setLevelMenu(e.target.outerText);
          setLevelStatus(!levelStatus);
        }}
        product={levelMenu}
        dropdownAction={() => {
          setLevelStatus(!levelStatus);
        }}
        dropdownList={levelListMenu}
        productStatus={levelStatus}
      />
      <div className="input-text-cont">
        <label className="label">Leadership experience</label>
        <textarea
          style={{
            borderBottom: inputFile?.experience ? "2px solid #029247" : "none",
            background: inputFile?.experience ? "#F2FCF5" : "#F6F6F6",
          }}
          value={inputFile?.experience}
          type="textarea"
          className="input-text !w-full"
          maxLength={1000}
          onChange={handleChange}
          name="experience"
          placeholder="share your experience..."
        />
      </div>
      <button
        onClick={proceed}
        className="mx-auto mt-5 flex w-3/4 items-center justify-center rounded-full bg-[#029247] px-6 py-3 font-semibold text-white sm:w-1/2"
      >
        Continue
      </button>
    </div>
  );
};
export const InputSegmentTwo = ({
  handleViewChange,
  inputFile,
  handleChange,
  relationStatus,
  setRelationStatus,
  relationListMenu,
  setRelationMenu,
  relationMenu,
}) => {
  const proceed = (e) => {
    e?.preventDefault();
    const containsNonNumber = (str) => {
      const regex = /[^0-9]/; // Matches any character that is not a digit
      return regex.test(str);
    };
    if (inputFile?.parent === "") {
      Toast({ title: "Input Parent/guardian's full name", error: true });
      return;
    }
    if (inputFile?.parentNo === "") {
      Toast({
        title: "Input Parent/guardian’s IPPIS / staff number",
        error: true,
      });
      return;
    }
    if (inputFile?.pgfcl === "") {
      Toast({
        title: "Input Parent/guardian's FCL account number",
        error: true,
      });
      return;
    }
    if (containsNonNumber(inputFile?.pgfcl)) {
      Toast({
        title: "Invalid Fast Credit Account Number",
        error: true,
      });
      return;
    }
    if (inputFile?.pgfcl?.length !== 10) {
      Toast({
        title: "Invalid Fast Credit Account Number",
        error: true,
      });
      return;
    }

    if (relationMenu === "") {
      Toast({ title: "Select Relationship to applicant", error: true });
      return;
    } else {
      handleViewChange(4);
    }
  };
  return (
    <div className="w-full">
      <div className="flex flex-col space-x-2 sm:flex-row sm:items-center sm:justify-between">
        <div className="w-full sm:w-1/2">
          <InputForm
            label="Parent/guardian's first name & last name:"
            type="text"
            value={inputFile?.parent}
            handleChange={handleChange}
            name="parent"
            placeholder="john doe"
          />
        </div>
        <div className="w-full sm:w-1/2">
          <InputForm
            label="Parent/guardian’s IPPIS / staff number:"
            type="text"
            value={inputFile?.parentNo}
            handleChange={handleChange}
            name="parentNo"
            length={50}
            placeholder="PF000000"
          />{" "}
        </div>
      </div>
      <div className="flex flex-col space-x-2 sm:flex-row sm:items-center sm:justify-between">
        <div className="w-full sm:w-1/2">
          <InputForm
            label="Parent/guardian's FCL account number:"
            type="text"
            value={inputFile?.pgfcl}
            handleChange={handleChange}
            name="pgfcl"
            placeholder="100010101"
            length={10}
          />{" "}
        </div>
        <div className="w-full sm:w-1/2">
          <Input
            label="Relationship to applicant"
            dropdown={true}
            action={(e) => {
              setRelationMenu(e.target.outerText);
              setRelationStatus(!relationStatus);
            }}
            product={relationMenu}
            dropdownAction={() => {
              setRelationStatus(!relationStatus);
            }}
            dropdownList={relationListMenu}
            productStatus={relationStatus}
          />
        </div>
      </div>

      <button
        onClick={proceed}
        className="mx-auto mt-5 flex w-3/4 items-center justify-center rounded-full bg-[#029247] px-6 py-3 font-semibold text-white sm:w-1/2"
      >
        Continue
      </button>
    </div>
  );
};

export const InputSegmentThree = ({
  hiddenFileInput2,
  hiddenFileInput,
  file,
  file2,
  file3,
  file4,
  file5,
  file6,
  file7,clipText,
  handleFileChange2,
  handleFileChange3,
  handleFileChange4,
  handleFileChange6,
  handleFileChange5,
  handleFileChange7,
  handleFileChange,
  handleSubmit,
  loading,
  handleClick,
  handleClick2,
  handleClick3,
  handleClick4,
  handleClick5,
  handleClick6,
  handleClick7,
  hiddenFileInput3,
  hiddenFileInput4,
  hiddenFileInput5,
  hiddenFileInput6,
  hiddenFileInput7,
}) => {
  const proceed = (e) => {
    e?.preventDefault();
    if (!file) {
      Toast({ title: "Upload Transcript", error: true });
      return;
    }
    if (!file2) {
      Toast({ title: "Upload Letter of recommendation", error: true });
      return;
    }
    if (!file3) {
      Toast({ title: "Upload Personal Statement", error: true });
      return;
    }
    if (!file4) {
      Toast({ title: "Upload Student ID", error: true });
      return;
    }
    // if (!file5) {
    //   Toast({ title: "Upload Parents Work ID", error: true });
    //   return;
    // }
    // if (!file6) {
    //   Toast({ title: "Upload Student Passport Size Photograph", error: true });
    //   return;
    // }
    // if (!file7) {
    //   Toast({ title: "Upload Letter of Admission", error: true });
    //   return;
    // }
    else {
      handleSubmit();
    }
  };
  return (
    <div className="w-full space-y-5">
      <div className="flex flex-col space-x-2 sm:flex-row sm:items-center sm:justify-between">
        <div className="w-full sm:w-1/2">
          <div className="input__form">
            <label htmlFor="LastSemester" className="label">
              Upload Last Semester Result:
            </label>

          <span
              style={{
                width: "auto",
                borderBottom: file?.name ? "2px solid #029247" : "none",
                background: file?.name ? "#F2FCF5" : "#F6F6F6",
              }}
              className="input flex items-center space-x-4 !text-[#BABABB]"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 20 24"
                fill="none"
              >
                <path
                  d="M2.69311 21.7248C1.24114 20.6275 0.377431 19.1913 0.101974 17.4163C-0.173484 15.6412 0.237467 14.0277 1.33483 12.5758L9.11283 2.28439C9.8846 1.26323 10.9048 0.667983 12.1734 0.49866C13.4421 0.329337 14.587 0.63056 15.6081 1.40233C16.6452 2.18616 17.2515 3.2084 17.4268 4.46906C17.6022 5.72971 17.2979 6.8786 16.5141 7.91572L9.3873 17.3455C8.92906 17.9518 8.33274 18.3096 7.59833 18.4189C6.86392 18.5282 6.19356 18.3537 5.58724 17.8955C4.98093 17.4373 4.64219 16.824 4.57102 16.0557C4.49985 15.2875 4.69942 14.5922 5.16971 13.9699L12.2604 4.58801L13.2177 5.31154L6.07279 14.7653C5.80749 15.1163 5.69707 15.5029 5.74151 15.9251C5.78596 16.3472 5.97571 16.6849 6.31078 16.9382C6.64585 17.1914 7.0195 17.2858 7.43174 17.2213C7.84398 17.1568 8.17672 16.957 8.42996 16.622L15.5749 7.16825C16.1537 6.40238 16.3748 5.55419 16.2383 4.62368C16.1017 3.69317 15.6505 2.9385 14.8846 2.35967C14.1187 1.78084 13.2696 1.55271 12.3371 1.67527C11.4046 1.79783 10.649 2.24205 10.0702 3.00792L2.25599 13.3472C1.37569 14.5119 1.05388 15.8168 1.29055 17.2616C1.52723 18.7065 2.23592 19.8752 3.41664 20.7675C4.61331 21.6719 5.93799 22.0338 7.39066 21.8532C8.84334 21.6725 10.0159 20.9918 10.9082 19.8111L18.7224 9.47187L19.6797 10.1954L11.8836 20.5107C10.7863 21.9627 9.34318 22.8024 7.55433 23.0298C5.76548 23.2572 4.14507 22.8222 2.69311 21.7248Z"
                  fill="#BABABB"
                />
              </svg>
              <p
                onClick={handleClick}
                style={{
                  color: file?.name ? "#333" : "#BABABB",
                }}
                className="cursor-pointer text-sm"
              >
                {file?.name ? clipText(file?.name) : " Upload PDF (Max 3MB)"}
              </p>
            </span>
            <input
              onChange={handleFileChange}
              ref={hiddenFileInput}
              className="input hidden"
              type="file"
              accept=".pdf"
            />
          </div>
        </div>
        <div className="w-full sm:w-1/2">
          <div className="input__form">
            <label htmlFor="Admission" className="label">
              Admission Letter
            </label>
            <span
              style={{
                width: "auto",
                borderBottom: file2?.name ? "2px solid #029247" : "none",
                background: file2?.name ? "#F2FCF5" : "#F6F6F6",
              }}
              className="input flex items-center space-x-4 !text-[#BABABB]"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 20 24"
                fill="none"
              >
                <path
                  d="M2.69311 21.7248C1.24114 20.6275 0.377431 19.1913 0.101974 17.4163C-0.173484 15.6412 0.237467 14.0277 1.33483 12.5758L9.11283 2.28439C9.8846 1.26323 10.9048 0.667983 12.1734 0.49866C13.4421 0.329337 14.587 0.63056 15.6081 1.40233C16.6452 2.18616 17.2515 3.2084 17.4268 4.46906C17.6022 5.72971 17.2979 6.8786 16.5141 7.91572L9.3873 17.3455C8.92906 17.9518 8.33274 18.3096 7.59833 18.4189C6.86392 18.5282 6.19356 18.3537 5.58724 17.8955C4.98093 17.4373 4.64219 16.824 4.57102 16.0557C4.49985 15.2875 4.69942 14.5922 5.16971 13.9699L12.2604 4.58801L13.2177 5.31154L6.07279 14.7653C5.80749 15.1163 5.69707 15.5029 5.74151 15.9251C5.78596 16.3472 5.97571 16.6849 6.31078 16.9382C6.64585 17.1914 7.0195 17.2858 7.43174 17.2213C7.84398 17.1568 8.17672 16.957 8.42996 16.622L15.5749 7.16825C16.1537 6.40238 16.3748 5.55419 16.2383 4.62368C16.1017 3.69317 15.6505 2.9385 14.8846 2.35967C14.1187 1.78084 13.2696 1.55271 12.3371 1.67527C11.4046 1.79783 10.649 2.24205 10.0702 3.00792L2.25599 13.3472C1.37569 14.5119 1.05388 15.8168 1.29055 17.2616C1.52723 18.7065 2.23592 19.8752 3.41664 20.7675C4.61331 21.6719 5.93799 22.0338 7.39066 21.8532C8.84334 21.6725 10.0159 20.9918 10.9082 19.8111L18.7224 9.47187L19.6797 10.1954L11.8836 20.5107C10.7863 21.9627 9.34318 22.8024 7.55433 23.0298C5.76548 23.2572 4.14507 22.8222 2.69311 21.7248Z"
                  fill="#BABABB"
                />
              </svg>
              <p
                onClick={handleClick2}
                style={{
                  color: file2?.name ? "#333" : "#BABABB",
                }}
                className="cursor-pointer text-sm"
              >
                {file2?.name ? clipText(file2?.name) : "Upload PDF (Max 3MB)"}
              </p>
            </span>
            <input
              onChange={handleFileChange2}
              ref={hiddenFileInput2}
              className="input hidden"
              accept=".pdf"
              type="file"
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col space-x-2 sm:flex-row sm:items-center sm:justify-between">
        <div className="w-full sm:w-1/2">
          <div className="input__form">
            <label htmlFor="Personal" className="label">
              Personal Statement:
            </label>

            <span
              style={{
                width: "auto",
                borderBottom: file3?.name ? "2px solid #029247" : "none",
                background: file3?.name ? "#F2FCF5" : "#F6F6F6",
              }}
              className="input flex items-center space-x-4 !text-[#BABABB]"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 20 24"
                fill="none"
              >
                <path
                  d="M2.69311 21.7248C1.24114 20.6275 0.377431 19.1913 0.101974 17.4163C-0.173484 15.6412 0.237467 14.0277 1.33483 12.5758L9.11283 2.28439C9.8846 1.26323 10.9048 0.667983 12.1734 0.49866C13.4421 0.329337 14.587 0.63056 15.6081 1.40233C16.6452 2.18616 17.2515 3.2084 17.4268 4.46906C17.6022 5.72971 17.2979 6.8786 16.5141 7.91572L9.3873 17.3455C8.92906 17.9518 8.33274 18.3096 7.59833 18.4189C6.86392 18.5282 6.19356 18.3537 5.58724 17.8955C4.98093 17.4373 4.64219 16.824 4.57102 16.0557C4.49985 15.2875 4.69942 14.5922 5.16971 13.9699L12.2604 4.58801L13.2177 5.31154L6.07279 14.7653C5.80749 15.1163 5.69707 15.5029 5.74151 15.9251C5.78596 16.3472 5.97571 16.6849 6.31078 16.9382C6.64585 17.1914 7.0195 17.2858 7.43174 17.2213C7.84398 17.1568 8.17672 16.957 8.42996 16.622L15.5749 7.16825C16.1537 6.40238 16.3748 5.55419 16.2383 4.62368C16.1017 3.69317 15.6505 2.9385 14.8846 2.35967C14.1187 1.78084 13.2696 1.55271 12.3371 1.67527C11.4046 1.79783 10.649 2.24205 10.0702 3.00792L2.25599 13.3472C1.37569 14.5119 1.05388 15.8168 1.29055 17.2616C1.52723 18.7065 2.23592 19.8752 3.41664 20.7675C4.61331 21.6719 5.93799 22.0338 7.39066 21.8532C8.84334 21.6725 10.0159 20.9918 10.9082 19.8111L18.7224 9.47187L19.6797 10.1954L11.8836 20.5107C10.7863 21.9627 9.34318 22.8024 7.55433 23.0298C5.76548 23.2572 4.14507 22.8222 2.69311 21.7248Z"
                  fill="#BABABB"
                />
              </svg>
              <p
                onClick={handleClick3}
                style={{
                  color: file3?.name ? "#333" : "#BABABB",
                }}
                className="cursor-pointer text-sm"
              >
                {file3?.name ? clipText(file3?.name) : " Upload PDF (Max 3MB):"}
              </p>
            </span>
            <input
              onChange={handleFileChange3}
              ref={hiddenFileInput3}
              className="input hidden"
              type="file"
              accept=".pdf"
            />
          </div>
        </div>
        <div className="w-full sm:w-1/2">
          <div className="input__form">
            <label htmlFor="idcard" className="label">
              Student ID:
            </label>

            <span
              style={{
                width: "auto",
                borderBottom: file4?.name ? "2px solid #029247" : "none",
                background: file4?.name ? "#F2FCF5" : "#F6F6F6",
              }}
              className="input flex items-center space-x-4 !text-[#BABABB]"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 20 24"
                fill="none"
              >
                <path
                  d="M2.69311 21.7248C1.24114 20.6275 0.377431 19.1913 0.101974 17.4163C-0.173484 15.6412 0.237467 14.0277 1.33483 12.5758L9.11283 2.28439C9.8846 1.26323 10.9048 0.667983 12.1734 0.49866C13.4421 0.329337 14.587 0.63056 15.6081 1.40233C16.6452 2.18616 17.2515 3.2084 17.4268 4.46906C17.6022 5.72971 17.2979 6.8786 16.5141 7.91572L9.3873 17.3455C8.92906 17.9518 8.33274 18.3096 7.59833 18.4189C6.86392 18.5282 6.19356 18.3537 5.58724 17.8955C4.98093 17.4373 4.64219 16.824 4.57102 16.0557C4.49985 15.2875 4.69942 14.5922 5.16971 13.9699L12.2604 4.58801L13.2177 5.31154L6.07279 14.7653C5.80749 15.1163 5.69707 15.5029 5.74151 15.9251C5.78596 16.3472 5.97571 16.6849 6.31078 16.9382C6.64585 17.1914 7.0195 17.2858 7.43174 17.2213C7.84398 17.1568 8.17672 16.957 8.42996 16.622L15.5749 7.16825C16.1537 6.40238 16.3748 5.55419 16.2383 4.62368C16.1017 3.69317 15.6505 2.9385 14.8846 2.35967C14.1187 1.78084 13.2696 1.55271 12.3371 1.67527C11.4046 1.79783 10.649 2.24205 10.0702 3.00792L2.25599 13.3472C1.37569 14.5119 1.05388 15.8168 1.29055 17.2616C1.52723 18.7065 2.23592 19.8752 3.41664 20.7675C4.61331 21.6719 5.93799 22.0338 7.39066 21.8532C8.84334 21.6725 10.0159 20.9918 10.9082 19.8111L18.7224 9.47187L19.6797 10.1954L11.8836 20.5107C10.7863 21.9627 9.34318 22.8024 7.55433 23.0298C5.76548 23.2572 4.14507 22.8222 2.69311 21.7248Z"
                  fill="#BABABB"
                />
              </svg>
              <p
                onClick={handleClick4}
                style={{
                  color: file4?.name ? "#333" : "#BABABB",
                }}
                className="cursor-pointer text-sm"
              >
                {file4?.name ? clipText(file4?.name) : "Upload PDF (Max 3MB)"}
              </p>
            </span>
            <input
              onChange={handleFileChange4}
              ref={hiddenFileInput4}
              className="input hidden"
              accept=".pdf"
              type="file"
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col space-x-2 sm:flex-row sm:items-center sm:justify-between">
        {/* <div className="w-full sm:w-1/2">
          <div className="input__form">
            <label htmlFor="workidcard" className="label">
              Parents Work ID:
            </label>

            <span
              style={{
                width: "auto",
                borderBottom: file5?.name ? "2px solid #029247" : "none",
                background: file5?.name ? "#F2FCF5" : "#F6F6F6",
              }}
              className="input flex items-center space-x-4 !text-[#BABABB]"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 20 24"
                fill="none"
              >
                <path
                  d="M2.69311 21.7248C1.24114 20.6275 0.377431 19.1913 0.101974 17.4163C-0.173484 15.6412 0.237467 14.0277 1.33483 12.5758L9.11283 2.28439C9.8846 1.26323 10.9048 0.667983 12.1734 0.49866C13.4421 0.329337 14.587 0.63056 15.6081 1.40233C16.6452 2.18616 17.2515 3.2084 17.4268 4.46906C17.6022 5.72971 17.2979 6.8786 16.5141 7.91572L9.3873 17.3455C8.92906 17.9518 8.33274 18.3096 7.59833 18.4189C6.86392 18.5282 6.19356 18.3537 5.58724 17.8955C4.98093 17.4373 4.64219 16.824 4.57102 16.0557C4.49985 15.2875 4.69942 14.5922 5.16971 13.9699L12.2604 4.58801L13.2177 5.31154L6.07279 14.7653C5.80749 15.1163 5.69707 15.5029 5.74151 15.9251C5.78596 16.3472 5.97571 16.6849 6.31078 16.9382C6.64585 17.1914 7.0195 17.2858 7.43174 17.2213C7.84398 17.1568 8.17672 16.957 8.42996 16.622L15.5749 7.16825C16.1537 6.40238 16.3748 5.55419 16.2383 4.62368C16.1017 3.69317 15.6505 2.9385 14.8846 2.35967C14.1187 1.78084 13.2696 1.55271 12.3371 1.67527C11.4046 1.79783 10.649 2.24205 10.0702 3.00792L2.25599 13.3472C1.37569 14.5119 1.05388 15.8168 1.29055 17.2616C1.52723 18.7065 2.23592 19.8752 3.41664 20.7675C4.61331 21.6719 5.93799 22.0338 7.39066 21.8532C8.84334 21.6725 10.0159 20.9918 10.9082 19.8111L18.7224 9.47187L19.6797 10.1954L11.8836 20.5107C10.7863 21.9627 9.34318 22.8024 7.55433 23.0298C5.76548 23.2572 4.14507 22.8222 2.69311 21.7248Z"
                  fill="#BABABB"
                />
              </svg>
              <p
                onClick={handleClick5}
                style={{
                  color: file5?.name ? "#333" : "#BABABB",
                }}
                className="cursor-pointer text-sm"
              >
                {file5?.name ? clipText(file5?.name) : "Upload PDF (Max 3MB):"}
              </p>
            </span>
            <input
              onChange={handleFileChange5}
              ref={hiddenFileInput5}
              className="input hidden"
              type="file"
              accept=".pdf"
            />
          </div>
        </div> */}
        {/* <div className="w-full sm:w-1/2">
          <div className="input__form">
            <label htmlFor="passport" className="label">
              Student Passport Size Photograph:
            </label>

            <span
              style={{
                width: "auto",
                borderBottom: file6?.name ? "2px solid #029247" : "none",
                background: file6?.name ? "#F2FCF5" : "#F6F6F6",
              }}
              className="input flex items-center space-x-4 !text-[#BABABB]"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 20 24"
                fill="none"
              >
                <path
                  d="M2.69311 21.7248C1.24114 20.6275 0.377431 19.1913 0.101974 17.4163C-0.173484 15.6412 0.237467 14.0277 1.33483 12.5758L9.11283 2.28439C9.8846 1.26323 10.9048 0.667983 12.1734 0.49866C13.4421 0.329337 14.587 0.63056 15.6081 1.40233C16.6452 2.18616 17.2515 3.2084 17.4268 4.46906C17.6022 5.72971 17.2979 6.8786 16.5141 7.91572L9.3873 17.3455C8.92906 17.9518 8.33274 18.3096 7.59833 18.4189C6.86392 18.5282 6.19356 18.3537 5.58724 17.8955C4.98093 17.4373 4.64219 16.824 4.57102 16.0557C4.49985 15.2875 4.69942 14.5922 5.16971 13.9699L12.2604 4.58801L13.2177 5.31154L6.07279 14.7653C5.80749 15.1163 5.69707 15.5029 5.74151 15.9251C5.78596 16.3472 5.97571 16.6849 6.31078 16.9382C6.64585 17.1914 7.0195 17.2858 7.43174 17.2213C7.84398 17.1568 8.17672 16.957 8.42996 16.622L15.5749 7.16825C16.1537 6.40238 16.3748 5.55419 16.2383 4.62368C16.1017 3.69317 15.6505 2.9385 14.8846 2.35967C14.1187 1.78084 13.2696 1.55271 12.3371 1.67527C11.4046 1.79783 10.649 2.24205 10.0702 3.00792L2.25599 13.3472C1.37569 14.5119 1.05388 15.8168 1.29055 17.2616C1.52723 18.7065 2.23592 19.8752 3.41664 20.7675C4.61331 21.6719 5.93799 22.0338 7.39066 21.8532C8.84334 21.6725 10.0159 20.9918 10.9082 19.8111L18.7224 9.47187L19.6797 10.1954L11.8836 20.5107C10.7863 21.9627 9.34318 22.8024 7.55433 23.0298C5.76548 23.2572 4.14507 22.8222 2.69311 21.7248Z"
                  fill="#BABABB"
                />
              </svg>
              <p
                onClick={handleClick6}
                style={{
                  color: file6?.name ? "#333" : "#BABABB",
                }}
                className="cursor-pointer text-sm"
              >
                {file6?.name
                  ? file6?.name
                  : " Upload PDF (Max 3MB)"}
              </p>
            </span>
            <input
              onChange={handleFileChange6}
              ref={hiddenFileInput6}
              className="input hidden"
              type="file"
              accept=".pdf"
            />
          </div>
        </div> */}
      </div>

      {/* <div className="input__form">
        <label htmlFor="admission" className="label">
          Letter of Admission:
        </label>

        <span
          style={{
            width: "auto",
            borderBottom: file7?.name ? "2px solid #029247" : "none",
            background: file7?.name ? "#F2FCF5" : "#F6F6F6",
          }}
          className="input flex items-center space-x-4 !text-[#BABABB]"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 20 24"
            fill="none"
          >
            <path
              d="M2.69311 21.7248C1.24114 20.6275 0.377431 19.1913 0.101974 17.4163C-0.173484 15.6412 0.237467 14.0277 1.33483 12.5758L9.11283 2.28439C9.8846 1.26323 10.9048 0.667983 12.1734 0.49866C13.4421 0.329337 14.587 0.63056 15.6081 1.40233C16.6452 2.18616 17.2515 3.2084 17.4268 4.46906C17.6022 5.72971 17.2979 6.8786 16.5141 7.91572L9.3873 17.3455C8.92906 17.9518 8.33274 18.3096 7.59833 18.4189C6.86392 18.5282 6.19356 18.3537 5.58724 17.8955C4.98093 17.4373 4.64219 16.824 4.57102 16.0557C4.49985 15.2875 4.69942 14.5922 5.16971 13.9699L12.2604 4.58801L13.2177 5.31154L6.07279 14.7653C5.80749 15.1163 5.69707 15.5029 5.74151 15.9251C5.78596 16.3472 5.97571 16.6849 6.31078 16.9382C6.64585 17.1914 7.0195 17.2858 7.43174 17.2213C7.84398 17.1568 8.17672 16.957 8.42996 16.622L15.5749 7.16825C16.1537 6.40238 16.3748 5.55419 16.2383 4.62368C16.1017 3.69317 15.6505 2.9385 14.8846 2.35967C14.1187 1.78084 13.2696 1.55271 12.3371 1.67527C11.4046 1.79783 10.649 2.24205 10.0702 3.00792L2.25599 13.3472C1.37569 14.5119 1.05388 15.8168 1.29055 17.2616C1.52723 18.7065 2.23592 19.8752 3.41664 20.7675C4.61331 21.6719 5.93799 22.0338 7.39066 21.8532C8.84334 21.6725 10.0159 20.9918 10.9082 19.8111L18.7224 9.47187L19.6797 10.1954L11.8836 20.5107C10.7863 21.9627 9.34318 22.8024 7.55433 23.0298C5.76548 23.2572 4.14507 22.8222 2.69311 21.7248Z"
              fill="#BABABB"
            />
          </svg>
          <p
            onClick={handleClick7}
            style={{
              color: file7?.name ? "#333" : "#BABABB",
            }}
            className="cursor-pointer text-sm"
          >
            {file7?.name ? clipText(file7?.name) : " Upload PDF (Max 3MB)"}
          </p>
        </span>
        <input
          onChange={handleFileChange7}
          ref={hiddenFileInput7}
          
          className="input hidden"
          type="file"
          accept=".pdf"
        />
      </div> */}

      <button
        onClick={proceed}
        disabled={loading}
        className="mx-auto mt-5 flex w-3/4 items-center justify-center rounded-full bg-[#029247] px-6 py-3 font-semibold text-white sm:w-1/2"
      >
        {loading ? (
          <svg
            className="mr-3 size-5 animate-spin rounded-full border-2 border-gray-50 border-l-transparent p-2.5 "
            viewBox="0 0 24 24"
          ></svg>
        ) : (
          "Submit"
        )}
      </button>
    </div>
  );
};

export const SuccessGrant = ({ handleViewChange }) => (
  <div>
    <div className="my-20 flex flex-col items-center justify-center">
      <motion.img
        initial={{
          opacity: 0,
          y: 50,
        }}
        whileInView={{
          opacity: 1,
          y: 0,
        }}
        transition={{
          duration: 1.5,
        }}
        src={image}
        className="mx-auto h-[100px] w-[100px]"
        alt="success"
      />
      <h3 className="!my-5 text-center ">
        Thank you, We have received your request.
      </h3>
      <p
        onClick={() => handleViewChange(1)}
        style={{
          background: "#dfdfdf",
        }}
        className="w-max cursor-pointer rounded px-8 py-2  font-semibold"
      >
        Done
      </p>
    </div>
  </div>
);
