import React, { useRef } from "react";
import image1 from "../assets/arrowWhite.svg";
import image from "../assets/greenArrow.svg";
import arr from "../assets/topArr.png";
import bg from "../assets/bgGrant.png";
import bg1 from "../assets/grantAbout.png";
import bg2 from "../assets/grantWoman.png";
import line from "../assets/greenline.png";
import line2 from "../assets/liness.png";
import naija from "../assets/naija.png";
import freq from "../assets/freq.png";
import grant2 from "../assets/grant2.png";
import grant3 from "../assets/grant3.png";
import grant4 from "../assets/grant4.png";
import grant5 from "../assets/grant5.png";
import apply from "../assets/apply.png";
import phone from "../assets/gphone.svg";
import email from "../assets/mailgr.png";
import how from "../assets/how.png";
import {
  enquiries,
  grant_apply_how,
  grant_apply_one,
  grant_apply_two,
  grant_note,
} from "../data/data";
import "../pages/grant/grant.css";
import { Link } from "react-router-dom";

export default function Grant() {
  const sectionRef = useRef(null);
  const sectionRef2 = useRef(null);

  const scrollToSection = () => {
    sectionRef?.current?.scrollIntoView({ behavior: "smooth" });
  };
  const scrollToSection2 = () => {
    sectionRef2?.current?.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <>
      <div ref={sectionRef2}></div>
      <div
        style={{
          background: "#F8F7FC",
        }}
        className="pt-32"
      >
        <h1 className="text-center text-2xl font-bold leading-10 sm:text-4xl">
          Achieve More with the
        </h1>
        <h1
          style={{
            paddingTop: ".5em",
          }}
          className="text-center text-2xl font-bold leading-10  sm:text-4xl"
        >
          Fast Credit Limited{" "}
          <span
            style={{
              background: "#D72927",
            }}
            className="rounded-lg px-2 text-white"
          >
            Scholarship Opportunity!
          </span>
        </h1>
        <p className="mx-auto my-5 w-3/4 text-center sm:w-1/2">
          <b
            style={{
              color: "#101720",
            }}
          >
            Fast Credit Limited
          </b>{" "}
          hereby invites applications from qualified Nigerian students for the
          2024{" "}
          <b
            style={{
              color: "#D72927",
            }}
          >
            Fast Credit Scholarship Program. 
          </b>
        </p>
        <div className="my-5 flex items-center justify-center space-x-6">
          <Link
            to="/scholarship-application/apply"
            className="flex w-max items-center space-x-3 rounded-lg bg-[#029247] px-6 py-2 text-white"
          >
            <button>Apply Now</button>
            <span className="size-10 rounded-full bg-white p-2">
              <img src={image} className="" alt="" />
            </span>
          </Link>
          <span
            onClick={scrollToSection}
            style={{
              border: "1px solid #101720",
            }}
            className="ml-10 flex w-max items-center space-x-3 rounded-lg bg-white px-6 py-2 text-[#101720]"
          >
            <button>Learn More</button>
            <span className="size-10 rounded-full bg-black p-2">
              <img src={image1} className="" alt="" />
            </span>
          </span>
        </div>
        <div className="flex items-center justify-center">
          <img src={bg} className="" alt="" />
        </div>
      </div>
      <div ref={sectionRef}></div>

      <GrantAbout />
      <GrantNote />
      <GrantEligibility />
      <GrantSelection />
      <GrantApply />
      <GrantApplySteps />
      <GrantEnquiries />
      <div
        onClick={scrollToSection2}
        className="my-5 ml-auto mr-20 flex w-max cursor-pointer items-center space-x-3 rounded-lg border-[1px] border-[#029247] px-6 py-2"
      >
        <b className="text-[#029247]">Back to the Top</b>
        <span className="flex size-8 items-center justify-center rounded-full bg-[#029247] p-2">
          <img src={arr} className="" alt="" />
        </span>
      </div>
    </>
  );
}

export const GrantAbout = () => (
  <section
    style={{}}
    className="mx-auto flex max-w-7xl flex-col-reverse space-y-7 rounded-xl p-5 sm:flex-row sm:justify-between sm:space-y-0 sm:p-10 lg:p-20"
  >
    <div className="w-full sm:w-1/2">
      <img src={bg1} className="w-3/4 sm:w-full" alt="" />
    </div>
    <div className="w-full sm:w-1/2">
      <h1 className="w-3/4 text-2xl font-semibold text-[#101720]">
        About the Scholarship Program
      </h1>
      <p className="mt-5 text-sm">
        The program is an avenue for rewarding our loyal customers by supporting
        the education of their children/wards with an aim to promote academic
        excellence and improve the skills of young Nigerians, by providing
        scholarship(s) of N250,000.00 for each of the 40 successful applicants.
        Eligible candidates must be undergraduates of accredited courses in a
        Nigerian Institution of higher learning.
      </p>
      <aside className="mt-4">
        <div className="flex items-center justify-between">
          <b className="text-xl text-[#029247]">Notification</b>
          <img src={line} className="w-3/4" alt="" />
        </div>
        <div className="my-5">
          <p
            style={{
              background: "#F4F4F4",
            }}
            className="rounded py-2 px-4 text-sm"
          >
            Applications should be submitted on or before{" "}
            <b className="text-[#D72927]"> 15th December 2024.  </b>
          </p>
          <p
            style={{
              background: "#F4F4F4",
            }}
            className="my-5 rounded py-2 px-4 text-sm"
          >
            All applicants are required to open Fast Credit accounts via USSD
            dial <b className="text-[#D72927]">  *5125# </b> or download our
            mobile app{" "}
            <a
              href="https://play.google.com/store/apps/details?id=com.fastcredit"
              target="_blank"
              className="font-semibold text-[#029247] underline"
              rel="noreferrer"
            >
              Download Here.
            </a>
          </p>
          <p
            style={{
              background: "#F4F4F4",
            }}
            className="my-5 rounded py-2 px-4 text-sm"
          >
            All successful applicants will be notified officially via mail and
            SMS
          </p>
          <p
            style={{
              background: "#F4F4F4",
            }}
            className="my-5 rounded py-2 px-4 text-sm"
          >
            A list of all winners will be published on the Fast Credit website{" "}
            <a
              href="https://fastcredit-ng.com"
              className="font-semibold text-[#029247] underline"
              target="_blank"
              rel="noreferrer"
            >
              https://fastcredit-ng.com
            </a>
          </p>
        </div>
      </aside>
    </div>
  </section>
);
export const GrantNote = () => (
  <section className="mx-auto flex max-w-7xl flex-col space-y-8 p-5 sm:flex-row sm:justify-between sm:space-y-0 sm:p-10 lg:p-20">
    <div className="w-full sm:w-1/2">
      <h1 className="text-2xl font-semibold  text-[#D72927]">NOTE:</h1>
      <div className="my-5 flex flex-col space-y-5">
        {grant_note.map((i) => (
          <div key={i} className="flex items-center space-x-5">
            <span
              style={{
                padding: "3px",
              }}
              className="h-1 w-1 rounded-full bg-black"
            />
            <p className="text-sm">{i}</p>
          </div>
        ))}
      </div>
      <aside className="rounded p-4 shadow">
        <b className="">
          The merit-based scholarship is open to undergraduates studying within
          Nigerian accredited institutions.
        </b>
      </aside>
    </div>
    <div className="w-full sm:w-1/2">
      <img src={bg2} className="xl:w-3/4" alt="" />
    </div>
  </section>
);

export const GrantEligibility = () => (
  <section
    style={{
      background: "#F8F7FC",
    }}
    className="p-5 sm:p-10 lg:p-16"
  >
    <div className="mx-auto  max-w-7xl">
      <div className="flex items-center justify-between">
        <span className="w-1/4">
          <h1 className="text-xl font-semibold ">
            {" "}
            <span className="text-[#D72927]">ELIGIBILITY</span> CRITERIA
          </h1>
          <p
            style={{
              border: "1px solid #101720",
            }}
            className=" flex w-max items-center space-x-3 rounded-lg bg-white px-6 py-2 text-[#101720]"
          >
            Applicants must:
          </p>
        </span>
        <span className="w-3/4 flex-1">
          <img src={line2} className="w-3/4" alt="" />
        </span>
      </div>
      <aside className="my-10 flex flex-col items-center justify-center lg:flex lg:flex-row lg:justify-around">
        <div className="flex flex-col space-y-10 md:flex-row md:space-y-0 lg:flex-col lg:space-y-10">
          <EligibilityCard
            title="Be a Nigerian citizen. "
            img={naija}
            num="01"
          />
          <EligibilityCard
            title="Parent/Guardian must be a new or existing Fast Credit borrowing customer and provide his account details."
            img={grant2}
            num="04"
          />
        </div>
        <div className="my-4 flex flex-col items-center justify-center space-y-10">
          <EligibilityCard
            title="Currently be enrolled for study in an accredited Nigerian institution."
            img={grant3}
            num="02"
          />
          <img className="w-full" src={freq} alt="card" />
        </div>

        <div className="flex flex-col space-y-5 md:flex-row md:space-y-0 lg:flex-col lg:space-y-10">
          <EligibilityCard
            title="Applications are open to all students of accredited Nigerian tertiary institutions - No minimum CGPA required. "
            img={grant4}
            num="03"
          />
          <EligibilityCard
            title="Applicant must also have/provide a new/existing Fast Credit account number"
            img={grant5}
            num="05"
          />
        </div>
      </aside>
    </div>
  </section>
);

export const EligibilityCard = ({ title, num, img }) => (
  <div className="m-6 flex h-[380px] w-[350px] flex-col space-y-5 rounded-lg bg-white p-3 shadow">
    <img className="w-full" src={img} alt="card" />
    <span className="flex space-x-3">
      <h2
        className="text-2xl font-semibold"
        style={{
          color: "#cfd1d2",
        }}
      >
        {num}
      </h2>
      <p className="text-sm font-semibold">{title}</p>
    </span>
  </div>
);
export const SelectionCard = ({ title, num }) => (
  <div className="my-2 flex h-[240px] w-[290px] flex-col justify-center space-y-5 rounded-lg bg-white p-3 shadow hover:bg-[#01602F] hover:text-white xl:h-[150px] xl:w-[350px]">
    <span className="flex">
      <h2
        className="font-semibold "
        style={{
          color: "#cfd1d2",
          fontSize: "50px",
        }}
      >
        {num}
      </h2>
      <p className="ml-10 text-sm">{title}</p>
    </span>
  </div>
);

export const GrantSelection = () => (
  <section
    style={{
      background: "#F8F7FC",
    }}
    className="p-5  sm:p-10 lg:p-16"
  >
    <div className="mx-auto  max-w-7xl">
      <div className="flex items-center justify-between">
        <span className="w-1/4">
          <h1 className="text-xl font-semibold">
            {" "}
            <span
              style={{
                color: "#D72927",
              }}
            >
              SELECTION
            </span>{" "}
            CRITERIA
          </h1>
          <p
            style={{
              border: "1px solid #101720",
            }}
            className=" flex w-max items-center space-x-3 rounded-lg bg-white px-6 py-2 text-[#101720]"
          >
            Applicants must:
          </p>
        </span>
        <span className="w-3/4 flex-1">
          <img src={line2} className="w-3/4" alt="" />
        </span>
      </div>
      <aside className="flex flex-wrap justify-evenly">
        <SelectionCard
          title="Application MUST be received not later than the specified deadline via
the scholarship portal ONLY. "
          num="1"
        />
        <SelectionCard
          title="Only candidates that meet the eligibility criteria will be engaged."
          num="2"
        />
        <SelectionCard
          title="Only the top 40 candidates will be shortlisted from each of the 36 states and the Federal Capital Territory for academic verification prior to scholarship offer."
          num="3"
        />
        <SelectionCard
          title="
        Candidates with falsified results will be disqualified. "
          num="4"
        />
        <SelectionCard
          title="Applications are open to all students of accredited Nigerian tertiary institutions - No minimum CGPA required."
          num="5"
        />
        <SelectionCard
          title="Only candidates that have successfully satisfied the above criteria will be contacted to receive the scholarship award. "
          num="6"
        />
      </aside>
      <h2 className="p-20 text-xl font-semibold">
        <span className="text-[#D72927]">HOW</span> TO APPLY 
      </h2>
    </div>
  </section>
);

export const GrantApply = () => (
  <>
    <section
      style={{
        background: "#fff",
      }}
      className="mx-auto flex w-full max-w-7xl flex-col justify-center space-x-5 space-y-5 p-5 sm:flex-row sm:space-y-0 sm:p-20"
    >
      <aside className="w-full sm:w-1/3">
        <img src={apply} className="w" alt="" />
      </aside>
      <aside className="w-full sm:w-1/2">
        <div className="w-full">
          <span className="space-y-4">
            {grant_apply_one.map((item) => (
              <span key={item.id} className="flex items-center space-x-3">
                <p
                  style={{
                    background: "#EAEAEA",
                  }}
                  className="flex h-7 w-7 items-center justify-center rounded-full p-2"
                >
                  {item.id}
                </p>
                <p className="text-sm">{item.title}</p>
              </span>
            ))}
          </span>
          <span className="mt-5 w-full flex-col items-center justify-center sm:grid sm:grid-cols-2 sm:gap-4 ">
            {grant_apply_two.map((item) => (
              <div
                key={item.id}
                style={{
                  background: "#DCFDEB",
                }}
                className="relative  m-2 h-[160px] w-3/4 rounded-xl p-5 md:!w-full"
              >
                <p
                  style={{
                    color: "#EAEAEA",
                    fontSize: "24px",
                  }}
                  className="font-semibold"
                >
                  {item.id}
                </p>
                <p
                  style={{
                    marginLeft: "auto",
                  }}
                  className="flex text-sm"
                >
                  {item.title}
                </p>
              </div>
            ))}
          </span>
        </div>
      </aside>
    </section>
  </>
);

export const GrantApplySteps = () => (
  <section
    style={{
      background: "#F8F7FC",
    }}
    className="p-5 sm:p-10 lg:p-20"
  >
    <div className="mx-auto  max-w-7xl">
      <h2 className="my-4 text-center text-2xl font-semibold">
        To Apply, Follow the{" "}
        <span className="text-[#D72927]">Steps Below:</span>{" "}
      </h2>
      <article className="flex flex-col sm:flex-row sm:space-x-5">
        <aside className="w-full space-y-5 sm:w-1/2">
          <div
            style={{
              background: "white",
            }}
            className="flex flex-col space-y-5 rounded-lg p-5 shadow "
          >
            <h1
              style={{
                color: "#D72927",
              }}
              className="text-xl font-semibold "
            >
              NOTE:
            </h1>
            <p>
              Multiple applications will lead to disqualification from the
              Scholarship board.
            </p>
            <b
              style={{
                background: "#E1FFEF",
              }}
              className="rounded p-3"
            >
              For further enquiries call:
              <a className="" href="tel:08131105876">
                +2348131105876{" "}
              </a>
            </b>
            <b
              style={{
                background: "#E1FFEF",
              }}
              className="rounded p-3"
            >
              Or send an email to:
              <a
                target="_blank"
                href="mailto:scholarships@fastcredit-ng.com"
                rel="noreferrer"
                className=""
              >
                scholarships@fastcredit-ng.com
              </a>
            </b>
          </div>
          <div>
            <img src={how} className="" alt="" />
          </div>
        </aside>
        <aside
          style={{
            background: "#fff",
          }}
          className="w-full space-y-5 rounded-lg p-5 sm:w-1/2"
        >
          <div className="flex flex-col space-y-7">
            <span className="flex items-center space-x-3">
              <p
                style={{
                  background: "#EAEAEA",
                }}
                className="flex h-7 w-7 items-center justify-center rounded-full p-2"
              >
                1
              </p>
              <p className="text-sm">
                Click on{" "}
                <b
                  style={{
                    color: "#029247",
                  }}
                  className=""
                >
                  "Apply Now"
                </b>
                 tab.
              </p>
            </span>
            {grant_apply_how.map((item) => (
              <span key={item.id} className="flex items-center space-x-3">
                <p
                  style={{
                    background: "#EAEAEA",
                  }}
                  className="flex h-7 w-7 items-center justify-center rounded-full p-2"
                >
                  {item.id}
                </p>
                <p className="text-sm">{item.title}</p>
              </span>
            ))}
            <span className="flex items-center space-x-3">
              <p
                style={{
                  background: "#EAEAEA",
                }}
                className="flex h-7 w-7 items-center justify-center rounded-full p-2"
              >
                7
              </p>
              <p className="text-sm">
                Click
                <b
                  style={{
                    color: "#029247",
                  }}
                  className=""
                >
                  "SUBMIT"
                </b>
              </p>
            </span>
          </div>
        </aside>
      </article>
    </div>
  </section>
);

export const GrantEnquiries = () => (
  <section
    style={{
      backgroundImage: "linear-gradient(to right ,#FFE1E0,#DAFFEC)",
    }}
    className="mx-auto my-10 w-[90%] max-w-7xl rounded-lg p-5 sm:h-[400px] sm:p-10 lg:w-[80%] lg:p-20"
  >
    <h2 className="text-2xl font-semibold">For Further Inquiries;</h2>
    <aside className="mt-10 flex flex-col space-y-5 sm:flex-row sm:justify-between sm:space-y-0">
      <div
        style={{
          background: "#DCFDEB",
        }}
        className="h-[200px] w-[300px] space-y-5 rounded-lg p-4"
      >
        <div className="mt-5 space-y-5">
          <a className="" href="tel:02018885046">
            <img src={phone} alt="" />
          </a>
          <a className="font-semibold" href="tel:02018885046">
            0201-888-5046
          </a>
        </div>

        <p className="text-sm">
          Call lines are open from 8:30am to 4:30pm (Mondays to Fridays)
        </p>
      </div>
      <div className="ml-5 space-y-7">
        {enquiries.map((i) => (
          <a
            key={i.title}
            target="_blank"
            href={i.link}
            className="flex items-center space-x-4"
            rel="noreferrer"
          >
            <img src={i.img} alt="" />
            <p className="text-sm">{i.title}</p>
          </a>
        ))}
        <div className="flex items-center space-x-4">
          <img src={email} alt="" />
          <span className="flex items-center space-x-2">
            <a
              target="_blank"
              href="mailto:scholarships@fastcredit-ng.com"
              rel="noreferrer"
              className="text-sm"
            >
              scholarships@fastcredit-ng.com
            </a>
          </span>
        </div>
      </div>
    </aside>
  </section>
);
