import Group from "../assets/group.svg";
import SMELoan from "../assets/sme-loan.svg";
import Invoice from "../assets/invoice.svg";
import Bnpl from "../assets/shopping-cart.svg";
import Coin from "../assets/coin.svg";
import Gameboy from "../assets/gameboy.svg";
import Cup from "../assets/cup.svg";
import Kiddies from "../assets/kiddies.svg";
import FCL from "../assets/fcl.svg";
import bnpl from "../assets/bnpl.svg";
import tnpl from "../assets/tnpl.png";
import tnpl2 from "../assets/tnpl.jpg";
import publicSector from "../assets/public.png";
import naira from "../assets/naira.svg";
import HealthLoan from "../assets/HealthLoan.svg";
import grouplending from "../assets/grouplending.svg";
import fcin from "../assets/fcin.svg";
import Target from "../assets/Target.svg";
import Credit from "../assets/credit.svg";
import kiiddies from "../assets/kiiddies.svg";
import Wallet from "../assets/Wallet.svg";
import bank from "../assets/bank.svg";
import phone from "../assets/phone.svg";
import tv from "../assets/tv.svg";
import people from "../assets/people.svg";
import whatsapp from "../assets/gwhatsapp.svg";
import twitter from "../assets/gtwitter.svg";
import insta from "../assets/ginsta.svg";
import facebook from "../assets/gfacebook.svg";

export const links = [
  {
    title: "Loans",
    link: "/loans",
    subnav: [
      {
        title: "Public Loan Sector",
        text: "All purpose cash Loans",
        icon: publicSector,
        link: "/loans/sector/public",
      },
      {
        title: "Buy Now Pay Later",
        text: "Buy and pay in installments",
        icon: Bnpl,
        // link: "/loans/bnpl",
        link: "https://fcladvantage.fastcredit-ng.com/",
      },
      {
        title: "Group Lending Scheme",
        text: "Welfare package for employees",
        icon: Group,
        link: "/loans/group ",
      },
      {
        title: "Travel Now Pay Later",
        text: "Travel and pay in installments",
        icon: tnpl,
        link: "https://travel.fastcredit-ng.com/",
      },
      {
        title: "Invoice Discounting",
        text: "Get Quick Funds",
        icon: Invoice,
        link: "/loans/invoice",
      },
      {
        title: "SME Loan",
        text: "Loan for  your business",
        icon: SMELoan,
        link: "/loans/sme",
      },
      {
        title: "private Loan Sector",
        text: "All purpose cash Loans",
        icon: Coin,
        link: "/loans/sector/private",
      },
      {
        title: "Loan Calculator",
        text: "Calculate your loans",
        icon: Gameboy,
        link: "/loans/calculator",
      },
    ],
  },
  {
    title: "Investments",
    link: "/investments",
    subnav: [
      {
        title: "FCL Investment Note",
        text: "Invest in your Quarterly, Bi-annually and Annually goals.",
        icon: FCL,
        link: "/investments/fcl",
      },
      {
        title: "Kiddies Swag",
        text: "Invest in your child for brighter future",
        icon: Kiddies,
        link: "/investments/kiddies",
      },
      {
        title: "Target Savings",
        text: "Save daily, weekly or monthly to achieve target goals",
        icon: Cup,
        link: "/investments/target",
      },
    ],
  },
  {
    title: "About Us",
    link: "/about",
  },
  {
    title: "Contact Us",
    link: "/contact",
  },
  // {
  //   title: "Open Account",
  //   link: "/account",
  // },
];

export const loan = [
  {
    id: 1,
    img: bnpl,
    text: "Buy Now, Pay Later",
    url: "/loans/bnpl",
  },
  {
    id: 2,
    img: naira,
    text: "Individual & Corporate Loans",
    url: "/loans/sector/private",
  },
  {
    id: 3,
    img: grouplending,
    text: "Group Lending",
    url: "/loans/group",
  },
  {
    id: 4,
    img: HealthLoan,
    text: "Health Loans",
    url: "",
  },
  {
    id: 5,
    img: Credit,
    text: "Invoice Discounting",
    url: "/loans/invoice",
  },
  {
    id: 8,
    img: tnpl2,
    text: "Travel Now, Pay later",
    url: "https://travel.fastcredit-ng.com/",
  },
];
export const invest = [
  {
    id: 1,
    img: fcin,
    text: "Fast Credit Investment note",
    url: "/investments/fcl",
  },
  {
    id: 2,
    img: Wallet,
    text: "Fast Credit Wallet",
    url: "",
  },
  {
    id: 3,
    img: Target,
    text: "Target Savings",
    url: "/investments/target",
  },
  {
    id: 4,
    img: kiiddies,
    text: "Kiddies Swag",
    url: "/investments/kiddies",
  },
];
export const pay = [
  {
    id: 1,
    img: bank,
    text: "Easy Inter Bank Transfer",
  },
  {
    id: 2,
    img: people,
    text: "Transfer Funds Seamlessly to Friends, Family & Business Partners",
  },
  {
    id: 3,
    img: phone,
    text: "Top Up Your Phone with Airtime & Data",
  },
  {
    id: 4,
    img: tv,
    text: "Sort Out Your Utility and Cable Bills",
  },
];

export const grant_note = [
  "The scholarship application is FREE.",
  "Fast Credit does not require you to make any payment in the course of this application.",
  "E-mail applications are NOT accepted.",
  "Fast Credit does NOT assign representatives/agents to assist applicants to apply.",
  "Any candidate found to have presented false documents will be disqualified at any level of the scholarship process.",
];
export const grant_apply_one = [
  {
    id: 1,
    title:
      "Every applicant should have a valid personal email account and phone number.",
  },
  {
    id: 2,
    title: "Applications by eligible candidates should be submitted online.",
  },
  {
    id: 3,
    title:
      "Candidates should include personal and educational details in the application.",
  },
  {
    id: 4,
    title:
      "Applicants should upload scanned PDF copies of the following as part of the application:",
  }
];
export const grant_apply_two = [
  {
    id: 1,
    title: "Last Semester Result",
  },
  {
    id: 2,
    title: "Admission Letter",
  },
  {
    id: 3,
    title: "Personal of Statement",
  },
  {
    id: 4,
    title: "Student ID Card",
  },
];
export const grant_apply_how = [
  {
    id: 2,
    title:
      " Enter your personal, educational and other information, then upload required scanned PDF documents.",
  },
  {
    id: 3,
    title:
      "Ensure the name used in applications matches the names on all documentation in same order. Upload a sworn affidavit or certificate if otherwise. ",
  },
  {
    id: 4,
    title:
      "Ensure you view all documents after uploading, to ensure that they are clear and accurate. ",
  },
  {
    id: 5,
    title: "Upload a passport photograph with a white background. ",
  },
  {
    id: 6,
    title: "Review application to avoid errors before final submission ",
  },
];

export const enquiries = [
  {
    link: "https://x.com/Fastcreditng ",
    title: "https://x.com/Fastcreditng ",
    img: twitter,
  },
  {
    link: "https://wa.me/2348131105876",
    title: " +2348131105876",
    img: whatsapp,
  },
  {
    link: "https://www.facebook.com/FastCreditLimited?mibextid=ZbWKwL",
    title: "https://www.facebook.com/FastCreditLimited?mibextid=ZbWKwL",
    img: facebook,
  },
  {
    link: "https://www.instagram.com/fastcreditng/",
    title: "https://www.instagram.com/fastcreditng/",
    img: insta,
  },
];